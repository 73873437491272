.app{
  display: grid;
  grid-template-columns: calc(100vw - 15em) 15em;
}

.canvas{
  height: 100vh;
}

.header-title{
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-primary-200);
  font-size: var(--header-font-size-100);
  padding-left: var(--space-100);
  font-weight: var(--header-font-weight-100);
  letter-spacing: 0.3ch;
}

.header-title::first-letter{
  font-size: 1.8rem;
}