.menu{
    padding: var(--space-100);
    background-color: var(--color-navy);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-100);
  }

  .menu-title{
    font-size: 1.2rem;
    font-weight: var(--header-font-weight-100);
    margin-bottom: var(--space-200);
  }

  .label{
    color: var(--color-light-100)
  }